import { Video as _Video } from "@2po-dpam/components";
import { type ContentfulImageData } from "@components/Image";
import classnames from "classnames";
import { graphql } from "gatsby";
import React from "react";

import * as style from "./style.module.scss";

type Props = {
    data: {
        vimeoLink?: string;
        title?: string;
        thumbnail: ContentfulImageData;
    };
    options?: any;
};

const getVimeoEmbedParams = (link: string) => {
    const url = new URL(link);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, id, unlistedHash] = url.pathname.split("/");
    return { id, unlistedHash };
};

const Video = ({ data: { vimeoLink, title, thumbnail }, options }: Props) => {
    if (!vimeoLink) return null;
    const { id, unlistedHash } = getVimeoEmbedParams(vimeoLink);

    return (
        <>
            {id && (
                <_Video
                    buttonPosition={options?.buttonPosition}
                    className={classnames(options?.className, {
                        [style.videoInColumn]: options?.columnSection,
                    })}
                    thumbnail={
                        <img
                            alt={title || "This is a vimeo video"}
                            src={thumbnail.image?.file?.url}
                        />
                    }
                    unlistedHash={unlistedHash}
                    videoId={id}
                />
            )}
        </>
    );
};

export default Video;

export const query = graphql`
    fragment Video on ContentfulContentVideo {
        contentful_id
        __typename
        vimeoLink
        title
        thumbnail {
            image {
                file {
                    url
                }
            }
        }
    }
`;
